'use strict';

import Loading from '../components/Loading';

const PassengerItem = React.lazy(() => import( /* webpackChunkName: "PassengerItem" */ '../components/PassengerItem'));

class SelectPassengerModal extends React.Component {
    constructor(props) {
        super(props);
        this.initState = {
            fid: null,
            ufid: null,
            search: '',
            passengers: [],
            isFetching: false,
        }
        this.state = this.initState;
        this.handleAdd = this.handleAdd.bind(this);
    }

    componentDidMount() {
        listenEvent('react.selectPassenger', data => {
            if(data === null) {
                this.setState(this.initState);
            } else {
                this.setState({fid: data.fid, ufid: data.ufid, isFetching: true});
                flightDataAction.getPassengerList({fid: data.fid, ufid: data.ufid}).then(response => {
                    this.setState({
                        isFetching: false,
                        passengers: response.items,
                    });
                })
            }
        })
    }

    handleAdd(passenger) {
        if(passenger.isAdded) return;
        const {fid, ufid} = this.state;
        this.setState({isFetching: true})
        flightDataAction.addPassenger({fid, ufid, upid: passenger.upid}).then(response => {
            this.setState({isFetching: false});
            if(response.status === 'success') {
                const newPassenger = {
                    ...passenger,
                    ufpid: response.ufpid,
                    isAdded: true,
                    error: null,
                };
                const passengers = [...this.state.passengers].map(p => {
                    if(p.upid === passenger.upid) return newPassenger;
                    return p
                });
                this.setState({passengers});
                raiseEvent('react.addPassenger', {passenger: newPassenger})
            } else {
                const passengers = [...this.state.passengers].map(p => {
                    if(p.upid === passenger.upid)
                        return {...passenger, error: response.message};
                    return p
                });
                this.setState({passengers});
            }
        })
    }

    getItems() {
        const {search, passengers} = this.state;
        if(search.trim().length === 0) return passengers;
        return passengers.filter(passenger => {
            if(passenger.firstName.toLowerCase().includes(search.trim().toLowerCase())) return true;
            if(passenger.lastName.toLowerCase().includes(search.trim().toLowerCase())) return true;
            if(passenger.thirdName.toLowerCase().includes(search.trim().toLowerCase())) return true;
            return false;
        })
    }

    render() {
        const {search, isFetching} = this.state;

        return <div>
            <div className="passenger-modal__title">
                {tMsg('Выбор из списка')}
            </div>
            <div className="passenger-modal__search">
                <input
                    type="text"
                    className="passenger-modal__search-input"
                    placeholder={tMsg('Поиск')}
                    value={search}
                    onChange={e => this.setState({search: e.target.value})}
                />
                <i className="icon-search"/>
            </div>
            <div className="passenger-modal__list">
                <React.Suspense>
                    {this.getItems().map((passenger, index) => <PassengerItem
                        key={`selectPassenger_${index}`}
                        itemKey={`selectPassenger_${index}`}
                        passenger={passenger}
                        buttons={[{
                            className: clsx('passanger-item__add', {added: passenger.isAdded}),
                            onClick: () => this.handleAdd(passenger),
                            text: passenger.isAdded ? tMsg('Добавлен') : tMsg('Добавить'),
                        }]}
                        error={passenger.error}
                    />)}
                </React.Suspense>
                <Loading active={isFetching} absolute={this.getItems().length > 0}/>
            </div>
        </div>
    }
}

const domContainer = document.querySelector('#reactSelectPassengerModal');
const root = ReactDOM.createRoot(domContainer);
root.render(React.createElement(SelectPassengerModal));
